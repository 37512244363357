<!-- 飞行详情 -->
<template>
  <div class="xianqing XfontOne">
    <div class="jz">
      <span>无人机总飞行次数</span>
      <span class="ls fontTwo jl13">
        <ul>
          <li class="fontTwo" v-for="(item, index) in cishu" :key="index">
            {{ item }}
          </li>
        </ul>
      </span>
      <span>次</span>
    </div>
    <div class="jz">
      <span>无人机总飞行里程</span>
      <span class="ls fontTwo jl13">
        <ul>
          <li class="fontTwo" v-for="(item, index) in licheng" :key="index">
            {{ item }}
          </li>
        </ul>
      </span>
      <span>Km</span>
    </div>
    <div class="jz">
      <span>无人机总飞行时长</span>
      <span class="ls fontTwo jl13">
        <ul>
          <li class="fontTwo" v-for="(item, index) in shichang" :key="index">
            {{ item }}
          </li>
        </ul>
      </span>
      <span>h</span>
    </div>
    <div class="jz">
      <span>无人机总执行任务量</span>
      <span class="ls fontTwo jl13">
        <ul>
          <li class="fontTwo" v-for="(item, index) in renwu" :key="index">
            {{ item }}
          </li>
        </ul>
      </span>
      <span>pcs</span>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      cishu: [],
      licheng: [],
      shichang: [],
      renwu: [],
    };
  },
  mounted() {
    this.abc();
  },
  methods: {
    abc() {
      API.zongshuju().then((res) => {
        //  获取过来的值为字符串,但是会带小数点,所以先转成数字型,再用parseInt()去掉小数点,因为split只接受字符型,所以重新转回字符型
        // 总次数
        let a = parseInt(Number(res[0].data));
        this.cishu = String(a).split("");
        // 总里程

        let b = parseInt(Number(res[1].data));
        let n = parseInt(Number(b));
        this.licheng = String(n).split("");
        // 总时长
        let c = parseInt(Number(res[2].data));
        this.shichang = String(c).split("");
        // 总任务
        let d = parseInt(Number(res[3].data));
        this.renwu = String(d).split("");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.xianqing {
  // width: 1888px;
  width: 100%;
  // height: 105px;
  background-image: url("~@/assets/newImage/bosstiaokuang.png");
  background-size: 100% 100%;
  margin: 22px 0 15px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .jl13 {
    margin: 0 10px 0 13px;
  }
}
.XfontOne {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 6px rgba(0, 187, 255, 0.7);
}
.fontTwo {
  font-size: 42px;
  font-family: UniDreamLED;
  color: #69f3ff;
}

ul {
  display: flex;
  list-style: none;
  padding: 5px 0 5px 0;
  li {
    width: 30px;
    background: #000000;
    border-radius: 4px;
    text-align: center;
    line-height: 5vh;
    margin: 0 4px 0 0;
  }
}
.jz {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>