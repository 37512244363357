<template>
  <div class="boss">
    <div class="chang">
      <div class="head wih100 h60 cp" @click="routerFn ">
        <img class="dib wih100 ht100" src="@/assets/newImage/bossTou.png" alt="">
      </div>
      <xiangqing class="gaodu"></xiangqing>
      <!-- 主体 -->
      <div class="zhuti">
        <!-- 左边部分 -->
        <div class="zuo">
          <!-- 无人机执行任务总量 -->
          <div class="zongliang">
            <div class="shangxian"></div>
            <div class="timu fontThree">无人机执行任务总量</div>
            <div class="xiaxian"></div>
          </div>
          <div class="uavzl">
            <uavrenwu></uavrenwu>
          </div>
          <!-- 无人机飞行总里程 -->
          <div class="uavlicheng">
            <div class="shangxian"></div>
            <div class="timu fontThree">{{year}}年度无人机飞行里程</div>
            <div class="xiaxian"></div>
          </div>
          <div class="uavfxlc">
            <uarzonglicheng></uarzonglicheng>
          </div>
          <!-- 2022年度无人机飞行架次 -->
          <div class="uavjiaci">
            <div class="shangxian"></div>
            <div class="timu fontThree">{{year}}年度无人机飞行架次</div>
            <div class="xiaxian"></div>
          </div>
          <div class="uavjc">
            <uavfxjc></uavfxjc>
          </div>
        </div>
        <!-- 中间 -->
        <div class="zhong">
          <div class="cap">
            <!-- 标签 -->
            <div class="biaoqian">
              <div
                class="kaung jb uav"
                @click="uav"
                :class="{ uavjin: jinId == 1 }"
              >
                <div class="imgone"></div>
                <span class="fontOnes kq">无人机在线</span>
                <div class="fontOnes chuizhi">
                  <span class="shuliang">{{ uavzx.online }}</span>
                  <span>个</span>
                </div>
              </div>
              <div
                class="kaung jb yc"
                :class="{ ycjin: jinId == 2 }"
                @click="yc"
              >
                <div class="imgtwo"></div>
                <span class="fontOnes kq">鹰巢在线</span>
                <div class="fontOnes chuizhi">
                  <span class="shuliang">{{ yczx.online }}</span>
                  <span>个</span>
                </div>
              </div>
              <div
                class="kaung jb jizhan"
                :class="{ jizhanjin: jinId == 3 }"
                @click="jizhan"
              >
                <div class="imgthree"></div>
                <span class="fontOnes kq">基站总数</span>
                <div class="fontOnes chuizhi">
                  <span class="shuliang">{{ jizhanzx.count }}</span>
                  <span>个</span>
                </div>
              </div>
              <!-- <div
                class="kaung jb fs"
                :class="{ fsjin: jinId == 4 }"
                @click="fs"
              >
                <div class="imgfour"></div>
                <span class="fontOne kq">飞手在线</span>
                <div class="fontOne chuizhi">
                  <span class="shuliang">{{ feishou.online }}</span>
                  <span>个</span>
                </div>
              </div> -->
            </div>
            <ditu ref="cesium_layer"></ditu>
          </div>
          <div class="biaoge">
            <div class="bioati">
              <div class="sexianshang"></div>
              <div class="fontThree">当前任务</div>
              <!-- <div class="neirong"></div> -->
              <div class="sexianxia"></div>
            </div>
            <!-- 当前任务 -->
            <div class="dangqianrenwu">
              <dangqianrenwu></dangqianrenwu>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="you">
          <!-- 人员统计 -->
          <div class="renyuan">
            <div class="shangxian"></div>
            <div class="timu fontThree">人员统计</div>
            <div class="xiaxian"></div>
          </div>
          <div class="uavrenyuan">
            <renyuan></renyuan>
          </div>
          <!-- 人员表格 -->
          <div class="Tabrenyuan">
            <renyuanTab></renyuanTab>
          </div>
          <!-- 设备统计 -->
          <div class="shebei">
            <div class="shangxian"></div>
            <div class="timu fontThree">设备统计</div>
            <div class="btn">
              <el-dropdown @command="sbbtn">
                <img
                  src="~@/assets/newImage/bossbtn.png"
                  class="el-dropdown-link"
                  style="width: 100%; height: 100%"
                />
                <el-dropdown-menu slot="dropdown" class="abcd">
                  <el-dropdown-item command="正常">正常</el-dropdown-item>
                  <el-dropdown-item command="损坏">损坏</el-dropdown-item>
                  <el-dropdown-item command="维修">维修</el-dropdown-item>
                  <el-dropdown-item command="保养">保养</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="xiaxian"></div>
          </div>
          <div class="uavshebei">
            <shebei ref="sheb"></shebei>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 组件引用
import xiangqing from "./particulars/index.vue";
//无人机执行任务总量
import uavrenwu from "./UAVTotalNumberOfExecutedTasks/index.vue";
// 年度无人机飞行里程
import uarzonglicheng from "./UAVTotalMileageFlown/index.vue";
// 年度无人机飞行架次
import uavfxjc from "./uavNumberOfFlight";
// 人员统计
import renyuan from "./PersonnelStatistics/index.vue";
// 设备统计
import shebei from "./deviceStatistics/index.vue";
// 当前任务表格
import dangqianrenwu from "./currentTask/index.vue";
// 人员统计表格
import renyuanTab from "./PersonnelForm/index.vue";
// 引入地图
import ditu from "./map/index.vue";
// import ditu from "../command/monitoring/components/map/index.vue";
// 引入地图弹窗方法
import Popup from "./methods/popup";
import PopupV2 from "./methods/popupV2";
import { Viewer, Utils } from "@/lib/cesium";
import API from "@/api";
export default {
  // inject: ["g_cesium_layer"],
  data() {
    return {
      jinId: 99,
      uavzx: {},
      yczx: {},
      jizhanzx: {},
      feishou: {},
      title: {
        id: 1,
        default: "icon1",
        active: "icon7",
        componentType: "Map",
        title: "无人机",
      },
      flag: true,
      status: true,
      online: 1,
      collection: {
        zysb: null,
        jwys: null,
        gzsb: null,
        jlys: null,
        kfq: null,
        zddq: null,
      },
      sl: 1,
      yingcaho: {
        id: 2,
        default: "icon2",
        active: "icon8",
        componentType: "Map",
        title: "鹰巢",
      },
      djz: {
        id: 3,
        default: "icon3",
        active: "icon9",
        componentType: "Map",
        title: "基站",
      },
      year:null
    };
  },
  components: {
    xiangqing,
    uavrenwu,
    uarzonglicheng,
    uavfxjc,
    renyuan,
    shebei,
    dangqianrenwu,
    renyuanTab,
    ditu,
  },
  computed: {
    cesium_layer() {
      return this.$refs["cesium_layer"];
    },
  },
  mounted() {
    let day = dayjs();
    this.year = day.format("YYYY");
    this.abc();
    this.uav();
  },
  methods: {
    routerFn(){
      this.$router.go(-1)
    },
    // 地图按钮
    async abc() {
      let res1 = (await API.HOME.dataInfo({ type: 1 })) || { name: "无人机" };
      this.uavzx = res1;
      let res2 = (await API.HOME.dataInfo({ type: 2 })) || { name: "鹰巢" };
      this.yczx = res2;
      // let res3 = (await API.HOME.dataInfo({ type: 3 })) || { name: "飞手" };
      // this.feishou = res3;
      let res4 = (await API.HOME.dataInfo({ type: 4 })) || { name: "基站" };
      this.jizhanzx = res4;
      // this.dataList = [res1, res2, res3, res4] || [];
      // console.log([res1, res2, res3, res4], "在线数量");
    },

    show_collection(cate) {
      let primitive = this.collection[cate];
      if (Cesium.defined(primitive)) {
        let viewer = this.cesium_layer.viewer();
        let scene = viewer.scene;
        if (!scene.primitives.contains(primitive)) {
          scene.primitives.add(primitive);
        }
        primitive.show = true;
      }
    },
    init_collection(cate) {
      // let viewer = this.cesium_layer.viewer()
      let viewer = this.$refs.cesium_layer.viewer();
      let scene = viewer.scene;
      if (Cesium.defined(this.collection[cate])) {
        scene.primitives.remove(this.collection[cate]);
      }
      this.collection[cate] = new Cesium.BillboardCollection({
        scene,
        show: true,
      });
      return this.collection[cate];
    },
    // 地图位置接口
    async getlist(id, isAbnormal, status) {
      let res = await API.MONITOR.getList({
        type: id,
        isAbnormal: isAbnormal,
        status: status,
      });
      this.add_zysb(res, id);
    },
    add_zysb(res, id) {
      let cate_name = "zysb";
      let collection = this.init_collection(cate_name);
      if (res) {
        let list = [];
        if (id == 1) {
          list.push(...res.deviceList);
        } else if (id == 2) {
          list.push(...res.nestList);
        } else if (id == 3) {
          list.push(...res.baseList);
        } else if (id == 4) {
          list.push(...res.landList);
        } else if (id == 5) {
          list.push(...res.mountList);
        } else if (id == 6) {
          list.push(...res.batteryList);
        }
        this.sl = list.length;
        list.forEach((item) => {
          item.longitude *= 1;
          item.latitude *= 1;
          if (item.latitude && item.longitude) {
            collection.add({
              position: Utils.transformWGS842Cartesian({
                lng: item.longitude,
                lat: item.latitude,
                alt: 0,
              }),
              // image: item.category === 4 ? require(`@/assets/images/factor/${cate_name}/${item.category}.png`) :  require(`@/assets/images/factor/${cate_name}/${item.category}${(item.category === 1 && item.status == 0) ? '-disable' : ''}.svg`),
              image: require(`@/assets/images/factor/sjq/${
                id *
                (item.reportStatus == 0
                  ? 100
                  : item.state == 1
                  ? 1
                  : 10 || item.landingStatus
                  ? 1
                  : 10)
              }.png`),
              scale: 1,
              width: 54,
              height: 89,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              id: JSON.stringify({
                ...item,
                entityType: "zdys",
              }),
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                2.2e5
              ),
              scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            });
          }
        });

        !this.cesium_layer.is_plate_mode() && this.show_collection(cate_name);
      }
    },
    // 无人机在线
    uav() {
      this.jinId = 1;
      this.getlist(this.title.id, null, this.online);
    },
    // 鹰巢在线
    yc() {
      this.jinId = 2;
      this.getlist(this.yingcaho.id, null, this.online)
    },
    // 基站总数
    jizhan() {
      this.jinId = 3;
      this.getlist(this.djz.id, null, this.online)
    },
    // 飞手在线
    fs() {
      this.jinId = 4;
    },
    sbbtn(command) {
      // 调用子组件方法,子组件设备统计
      this.$refs.sheb.zhuangtai(command);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.boss {
  background-image: url("~@/assets/newImage/bossbj.png");
  background-size: 100% 100%;

  pointer-events: auto;
  display: flex;
  justify-content: center;
  .chang {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    /* .head {
      width: 100%;
      height: 60px !important;
      img{
        width: 100%;
        height: 100%;
      }
    } */
  }
}
.gaodu {
  height: 9%;
}
.zhuti {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  .zuo {
    width: 24%;
    background-image: url("~@/assets/newImage/bosszuo.png");
    background-size: 100% 100%;
    padding: 0 16px 0 16px;
    //
    height: 99.5%;
  }
  .zhong {
    width: 50%;
    .cap {
      background-image: url("~@/assets/newImage/bosscap.png");
      background-size: 100% 100%;
      // height: 590px;
      height: 65.2%;
      margin: 0px 0 15px 0;
      position: relative;
      padding: 16px;
      .biaoqian {
        z-index: 99;
        position: absolute;
        right: 12px;
        top: 25px;
        height: 356px;
        width: 317px;
        .kaung {
          width: 100%;
          height: 77px;
          box-shadow: inset 0px 0px 17px 2px rgba(46, 181, 246, 0.5);
          border: 1px solid rgba(31, 222, 233, 0.5);
          margin: 0 0 16px 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 7px 16px 7px 16px;
        }
      }
    }
    .biaoge {
      // height: 227px;
      height: 32.1%;
      background-image: url("~@/assets/newImage/bosscap.png");
      background-size: 100% 100%;
      padding: 0 22px 0 22px;
    }
  }
  .you {
    width: 24%;
    background-image: url("~@/assets/newImage/bosszuo.png");
    background-size: 100% 100%;
    //
    height: 99.5%;
    padding: 0 16px 0 16px;
  }
}
// 18号字体,白色
.fontOnes {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
}
// 18 白色 有影音
.fontThree {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  // line-height: 24px;
  text-shadow: 0px 0px 6px rgba(0, 187, 255, 0.7);
}
//flex 并且居边
.jb {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
// 标签背景,以及鼠标悬浮
.uav {
  .imgone {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossuav.png");
    background-size: 100% 100%;
  }
}
.uav:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgone {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossuavjin.png");
    background-size: 100% 100%;
  }
}
.yc {
  .imgtwo {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossyingchao.png");
    background-size: 100% 100%;
  }
}
.yc:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgtwo {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossyingchaojin.png");
    background-size: 100% 100%;
  }
}
.jizhan {
  .imgthree {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossxinhaota.png");
    background-size: 100% 100%;
  }
}
.jizhan:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgthree {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossxinhaotaJin.png");
    background-size: 100% 100%;
  }
}
.fs {
  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishou.png");
    background-size: 100% 100%;
  }
}
.fs:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishouJin.png");
    background-size: 100% 100%;
  }
}
.bioati {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .neirong {
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("~@/assets/newImage/bossneirongkuang.png");
    background-size: 100% 100%;
  }
  .sexianshang {
    position: absolute;
    left: 0;
    top: 0;
    width: 977px;
    height: 1px;
    border-bottom: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .sexianxia {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 977px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
}
// 点击效果
.uavjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgone {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossuavjin.png");
    background-size: 100% 100%;
  }
}
.ycjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgtwo {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossyingchaojin.png");
    background-size: 100% 100%;
  }
}
.jizhanjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgthree {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossxinhaotaJin.png");
    background-size: 100% 100%;
  }
}
.fsjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;
  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishouJin.png");
    background-size: 100% 100%;
  }
}
// 小框标题样式
.zongliang {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
}
// 图表div样式
.uavzl {
  width: 100%;
  // height: 165px;
  height: 20%;
  margin: 22px 0 26px 0;
}
.uavlicheng {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
}
.uavfxlc {
  width: 100%;
  height: 19%;
  margin: 22px 0 23px 0;
}
.uavjiaci {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
}
.uavjc {
  width: 100%;
  height: 28%;
  margin: 22px 0 0px 0;
}
// 右边图表栏
.renyuan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
}
.uavrenyuan {
  width: 100%;
  height: 18%;
  margin: 22px 0 23px 0;
}
.shebei {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)
      )
      2 2;
    filter: blur(0px);
  }
  .btn {
    position: absolute;
    right: 4%;
    top: 21%;
    width: 24px;
    height: 23px;
    cursor: pointer;
  }
}
.uavshebei {
  width: 100%;
  height: 28%;
  margin: 22px 0 23px 0;
}
// 人员表格
.Tabrenyuan {
  width: 100%;
  height: 28%;
  margin: 0 0 0px 0;
}
// 当前任务
.dangqianrenwu {
  margin: 20px 0 11px 0;
  width: 100%;
  height: 60%;
}
// 弹框样式

.abcd {
  background: rgba(104, 104, 104, 0.5) !important;
  border: 1px solid transparent !important;
  border-radius: 0px;
  width: 79px !important;
  .el-dropdown-menu__item {
    background: none;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #aab7c3 !important;
    text-align: center;
    &:hover {
      background: #429ecc !important;
      color: #ffffff !important;
    }
  }
}
.kq {
  width: 100px;
  text-align: left;
}
.shuliang {
  width: 50px;
  text-align: right;
  display: inline-block;
  font-size: 40px;
  font-family: UniDreamLED;
  color: #69f3ff;
  margin: 0 10px 0 0;
}
.chuizhi {
  display: flex;
  align-items: center;
}
</style>