<!-- 当前任务表格 -->
<template>
  <div class="dangqianBle">
    <!-- 此处需要滚动动画所以我用手写表格 -->
    <div class="bd-box">
      <div class="tb-hd-box">
        <div class="tb-hd sj">事件内容</div>
        <div class="tb-hd">责任单位</div>
        <div class="tb-hd">警情类型</div>
        <div class="tb-hd">执行状态</div>
        <div class="tb-hd">发生时间</div>
      </div>
      <div
        class="tb-bd-box"
        id="hezi"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >
        <div class="tb-tr" v-for="(item, index) in arr" :key="index">
          <!-- 事件内容 -->
          <div class="td shijian" :title="item.taskTitle">{{ item.taskTitle }}</div>
          <!-- 责任单位 -->
          <div class="td">{{ item.orgName }}</div>
          <!-- 警种类型 -->
          <div
            class="td c"
            :class="{ a: item.taskCateId == 1, b: item.taskCateId == 2 }"
          >
            {{ leixing(item.taskCateId) }}
          </div>
          <!-- 执行状态 -->
          <div class="td zise">{{ stu(item.taskStatus) }}</div>
          <!-- 发生时间 -->
          <div class="td">{{ item.addtime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      arr: [],
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
      filter: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  async mounted() {
    await this.getApi();
    this.abc();
  },
  methods: {
    async getApi() {
      await API.dangqianrenwu(this.filter).then((res) => {
        this.arr = res;
      });
    },
    // 滚动条滚动效果
    abc() {
      let box = document.getElementById("hezi");
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop;
        //获得老位置
        this.newTop = this.oldTop + 5;
        //得到新位置
        box.scrollTop = this.newTop;
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0;
        }
      }, 100);
      //循环时间
    },
    // 鼠标悬停
    mouseOver() {
      clearInterval(this.times);
    },
    //鼠标离开
    mouseLeave() {
      this.abc();
    },
    // 执行状态
    stu(num) {
      if (num == 3) {
        return "执行中";
      } else {
        return "暂无";
      }
    },
    // 警情类型
    leixing(num) {
      if (num == 1) {
        return "警情任务";
      } else if (num == 2) {
        return "常态任务";
      } else if (num == 3) {
        return "临时紧急任务";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.dangqianBle {
  width: 100%;
  height: 100%;
  .bd-box {
    width: 100%;
    height: 100%;
    .tb-hd-box {
      width: 100%;
      height: 32px;
      box-shadow: inset 0px 0px 18px 2px rgba(56, 147, 192, 0.5);
      border: 1px solid rgba(31, 222, 233, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
    }
    .tb-bd-box {
      margin: 8px 0 0 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aab7c3;
      height: 16vh;
      overflow: scroll;
      overflow-x: hidden;
      .tb-tr {
        width: 100%;
        height: 32px;
        border: 1px solid rgba(31, 222, 233, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .shijian {
          //   width: 294px !important;
          flex: 1.8;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.tb-hd {
  flex: 1;
  text-align: center;
}
.td {
  flex: 1;
  text-align: center;
}
.sj {
  //   width: 294px !important;
  flex: 1.8;
  text-align: center;
  line-height: 32px;
}
.tb-tr {
  padding: 0 12px 0 12px;
  margin: 0 0 8px 0;
  cursor: pointer;
}
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
.zise {
  color: #ffb200ff;
}
// 警任务识别颜色
.a {
  color: #fb4a2d !important;
}
.b {
  color: #00d45c !important;
}
.c {
  color: #ffbd36;
}
</style>