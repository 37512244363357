// 创建弹窗对象的方法
var Popup = function (info) {
  this.constructor(info);
}
Popup.prototype.id = 0;
Popup.prototype.constructor = function (info) {
  var _this = this;
  _this.viewer = info.viewer;//弹窗创建的viewer
  _this.geometry = info.geometry;//弹窗挂载的位置
  _this.data = info.data;
  _this.del_real = info.del_real;
  _this.vueThis = info.arr;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }
  _this.id = "popup_" + _this.__proto__.id++;
  _this.ctn = $("<div class='bx-popup-ctn' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  let contentHtml = ''

  /**
   * 增加单位名称
   */
  if (_this.data?.anotherName) {
    contentHtml += `<div class="li"><div class="label">所属单位：</div><div class="text" style="width: 160px;">${_this.data.anotherName}</div></div>`
  }
  if (_this.data?.address) {
    contentHtml += `<div class="li"><div class="label">地址：</div><div class="text">${_this.data.address}</div></div>`
  }
  // if (_this.data?.longitude) {
  //   contentHtml += `<div class="li"><div class="label">经度：</div><div class="text">${_this.data.longitude}</div></div>`
  // }
  // if (_this.data?.latitude) {
  //   contentHtml += `<div class="li"><div class="label">纬度：</div><div class="text">${_this.data.latitude}</div></div>`
  // }
  // if (_this.data?.manager) {
  //   contentHtml += `<div class="li"><div class="label">负责人：</div><div class="text">${_this.data.manager}</div></div>`
  // }
  // if (_this.data?.bs) {
  //   contentHtml += `<div class="li"><div class="label">快反时间：</div><div class="text">${_this.data.bs}</div></div>`
  // }
  // if (_this.data?.unitIndexCode) {
  //   contentHtml += `<div class="li"><div class="hk-link" data-code="${_this.data.unitIndexCode}">视频查看</div></div>`
  // }
  // if (_this.data.typeId == 1) {
  //   contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为禁飞区</div></div>`
  // }
  // if (_this.data.typeId == 2) {
  //   contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为电子围栏</div></div>`
  // }
  // if (_this.data.typeId == 3) {
  //   contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为限高区</div></div>`
  // }
  // if (_this.data?.height) {
  //   contentHtml += `<div class="li"><div class="label">限高距离：</div><div class="text">${_this.data.height}</div></div>`
  // }


  //测试弹窗内容
  var testConfig = {
    header: _this.data.deviceName || _this.data.name || '',
    content: `<div>${contentHtml}</div>`,
  }
  _this.ctn.append(_this.createHtml(testConfig.header, testConfig.content));
  // 实时刷新
  _this.offsetWidth = _this.ctn.get(0).offsetWidth
  _this.offsetHeight = _this.ctn.get(0).offsetHeight

  _this.render(_this.geometry);
  // _this.eventListener = _this.viewer.clock.onTick.addEventListener(function (clock) {
  //   _this.render(_this.geometry);
  // })

  _this.eventListener = () => { // 每一帧都去计算气泡的正确位置
    _this.render(_this.geometry);
  }
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);

  _this.ctn.on("click", ".leaflet-popup-close-button", function (e) {
    e.stopPropagation();
    _this.close();
  });
}
Popup.prototype.render = function (geometry) {
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(_this.viewer.scene, geometry)
  // _this.ctn.css("position","absolute");
  _this.ctn.css("left", position.x - _this.offsetWidth / 2);
  _this.ctn.css("top", position.y - _this.offsetHeight - 10);
}
// 动态生成内容
Popup.prototype.createHtml = function (header, content) {
  var html = '<div class="bx-popup-header-ctn">' +
    header +
    '</div>' +
    '<div class="bx-popup-content-ctn" >' +
    '<div class="bx-popup-content" >' +
    content +
    '</div>' +
    '</div>' +
    '<div class="bx-popup-tip-container" >' +
    '<div class="bx-popup-tip" >' +
    '</div>' +
    '</div>' +
    '<div class="el-icon-close leaflet-popup-close-button"></div>';
  return html;
}
// 关闭弹窗按钮
Popup.prototype.close = function () {
  var _this = this;
  const { category, deviceHardId } = _this.data
  _this.ctn.remove();
  if (category === 1) {
    _this.del_real(deviceHardId)
  }
  // _this.viewer.clock.onTick.removeEventListener(_this.eventListener);
  _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
  // 防止点击关闭无法创建新的移动弹窗
  if (this.data.hasOwnProperty('regionList')) {
    const index = _this.vueThis.move_id_arr.indexOf('_this.data.id');
    _this.vueThis.move_id_arr.splice(index, 1)
  }

  if (this.data.hasOwnProperty('entityType')) {
    const index = _this.vueThis.move_id_arr.indexOf('_this.data.id');
    _this.vueThis.zdys_id_arr.splice(index, 1)
  }
}

export default Popup