<!-- 2022年度无人机飞行架次 -->
<template>
  <div id="uavjc"></div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      names: [],
      shuju: [],
    };
  },
  async mounted() {
    await this.abc();
    this.getEcharts();
  },
  methods: {
    async abc() {
      await API.zjc().then((res) => {
        res.forEach((item) => {
          this.names.push(item.orgName);
          this.shuju.push(item.flightSorties);
        });
      });
    },
    // 图表
    getEcharts() {
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#251000FF" },
          { offset: 0.5, color: "#965E11FF" },
          { offset: 1, color: "#FFDCA2FF" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#608731FF" },
          { offset: 1, color: "#F2FDAAFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#2E8F87FF" },
          { offset: 1, color: "#B7FFF9FF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000218FF" },
          { offset: 0.5, color: "#4C4EBCFF" },
          { offset: 1, color: "#BEBCFFFF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#1B0225FF" },
          { offset: 0.5, color: "#743ACCFF" },
          { offset: 1, color: "#CFA6FFFF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uavjc"));
      let option = {
        legend: {
          show: "true",
          top: "15",
          left: "left",
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          textStyle: {
            color: "#AAB7C3",
          },
          formatter: function (name) {
            let data = option.series[0].data;
            let tarValue = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value;
              }
            }
            return name

          },
        },
        grid: {
          top: 40,
          left: '10%',
          bottom: "15%",
          x: 0,
          x2: 0,
          // y2:80,
        },
        xAxis: {
          show: true,
          type: "category",
          data:this.names,
          axisLabel: {
            color: "#AAB7C3",
            fontSize: "13",
             interval: 0,
          },
          axisLine: {
            lineStyle: {
              type: "dashed", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
              
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "12",
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color:['#AAB7C3'],
            },
          },
        },
        series: [
          {
            data: this.shuju,
            name: "单位 :  次",
            type: "bar",
            backgroundStyle: {
              //   color: [color1, color2, color3, color4, color5],
            },

            label:{
              normal: {
              show:true,
              position:'top'
              },
              formatter:"{@value}"
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [color1, color2, color3, color4, color5];
                  return colorList[params.dataIndex];
                },
              },
            },
            barWidth: 20,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#uavjc {
  width: 100%;
  height: 26vh
}
</style>