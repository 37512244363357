<!-- 设备统计 -->
<template>
  <div class="shebeiguanli">
    <div class="dangqian">
      <span>当前统计状态</span>
      <span class="jj">:</span>
      <span
        class="zhengchang"
        :class="{ a: val == '损坏', b: val == '维修', c: val == '保养' }"
        >{{ val }}</span
      >
    </div>
    <div id="uavshebei"></div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      val: "正常",
      arr: [],
      names: [],
      shuju: [],
      zheng: [], //正常数组
      sun: [], //损坏数组,
      bao: [], //保养数组,
      wei: [], //维修数组,
    };
  },
  async mounted() {
    await this.abc();
    await this.newAr();
    this.getEcharts();
  },
  methods: {
    // 获取数据
    async abc() {
      await API.shebeitj().then((res) => {
        this.arr = res;
        res.forEach((res) => {
          if (res.status == "repair") {
            this.wei.push(res);
          } else if (res.status == "normal") {
            this.zheng.push(res);
          } else if (res.status == "damage") {
            this.sun.push(res);
          } else if (res.status == "care") {
            this.bao.push(res);
          }
        });
      });
    },
    // 根据状态挑选数据
    newAr() {
      this.names = [];
      this.shuju = [];
      if (this.val == "正常") {
        this.zheng.forEach((res) => {
          this.names.push(res.name);
          this.shuju.push(res.count);
        });
       this.getEcharts();
      } else if (this.val == "损坏") {
        this.sun.forEach((res) => {
          this.names.push(res.name);
          this.shuju.push(res.count);
        });
       this.getEcharts();
      } else if (this.val == "保养") {
        this.bao.forEach((res) => {
          this.names.push(res.name);
          this.shuju.push(res.count);
        });
        this.getEcharts();
      } else if (this.val == "维修") {
        this.wei.forEach((res) => {
          this.names.push(res.name);
          this.shuju.push(res.count);
        });
        this.getEcharts();
      }
    },
    // 当前统计状态,修改图表显示内容
    zhuangtai(stu) {
      this.val = stu;
      this.newAr();
    },
    // 图表
    getEcharts() {
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#251000FF" },
          { offset: 0.5, color: "#965E11FF" },
          { offset: 1, color: "#FFDCA2FF" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#608731FF" },
          { offset: 1, color: "#F2FDAAFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#2E8F87FF" },
          { offset: 1, color: "#B7FFF9FF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000218FF" },
          { offset: 0.5, color: "#4C4EBCFF" },
          { offset: 1, color: "#BEBCFFFF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#1B0225FF" },
          { offset: 0.5, color: "#743ACCFF" },
          { offset: 1, color: "#CFA6FFFF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uavshebei"));
      let option = {
        legend: {
          show: "true",
          top: "15",
          left: "left",
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          textStyle: {
            color: "#AAB7C3",
          },
        },
        grid: {
          top: 50,
          left: 30,
          bottom: "9.5%",
          x: 0,
          x2: 0,
          // y2:80,
        },
        xAxis: {
          show: true,
          type: "category",
          data: this.names,
          axisLabel: {
            color: "#AAB7C3",
            fontSize: "8",
            interval: 0,
            // rotate: 90
          },
          axisLine: {
            lineStyle: {
              type: "dashed", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "12",
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color:['#AAB7C3'],
            },
          },
        },
        series: [
          {
            data: this.shuju,
            // name: "当前状态统计 :  次",

            type: "bar",
            backgroundStyle: {
              //   color: [color1, color2, color3, color4, color5],
            },
            label:{
              normal: {
              show:true,
              position:'top'
              },
              formatter:"{@value}"
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [color1, color2, color3, color4, color5];
                  return colorList[params.dataIndex];
                },
              },
            },
            barWidth: 20,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shebeiguanli {
  width: 100%;
  height: 26vh;
  position: relative;
}
#uavshebei {
  width: 100%;
  height: 28.5vh;
}
.dangqian {
  position: absolute;
  top: 10px;
  left: 4px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #aab7c3;
}
.jj {
  margin: 0 6px 0 4px;
}
.zhengchang {
  color: #00d45cff;
}
.a {
  color: #ff4e0eff !important;
}
.b {
  color: #d4be00ff !important;
}
.c {
  color: #00c1d4ff !important;
}
</style>