<!-- 人员统计 -->
<template>
  <div class="renyuantu">
    <div id="renyuan"></div>
    <div class="tuli">
      <ul>
        <li class="kuang" v-for="(item,index) in arr" :key="index">
          <div :style="{color:item.color}" class="fontThree">{{ item.name }}</div>
          <div class="flexs">
            <span class="fontOne juzhong">{{ item.value }}</span>
            <span class="fontTwo jianju">人</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      arr: [],
      tuArr:[],//图例数组
      color:["#C174F3", "#29C2A1FF", "#287DE3FF"]
    };
  },
  async mounted() {
    await this.abc();
    this.getEcharts();
  },
  methods: {
    async abc() {
      await API.renyuantongji().then((res) => {
        let a = res.fujingCount;
        let b = res.jinghangCount;
        let c = res.minjingCount;
        this.arr = [
          { value: a, name: "辅警" ,color:this.color[0]},
          { value: b, name: "警航" ,color:this.color[1]},
          { value: c, name: "民警" ,color:this.color[2]},
        ];
      });
    },
    getEcharts() {
      const myChart = this.$echarts.init(document.getElementById("renyuan"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show:false,
          top: "center",
          selectedMode: "multiple",
          right: "20",
          orient: "hor",
          itemGap: 30,
          textStyle: {
            color: "auto",
            rich: {
              // 通过富文本rich给每个项设置样式，下面的oneone、twotwo、threethree可以理解为"每一列"的样式
              a: {
                width: 90,
                align: "left",
                fontSize: "14",
                // borderColor: "rgba(31,222,233,0.5000)",
                // borderWidth: 1,
                color: [
                  "#FF4D32FF",
                  "#540D8FFF",
                  "#242E91FF",
                  "#0A4DE8FF",
                  "#00E6A0FF",
                ],
              },
              b: {
                width: 25,
                align: "right",
                color: "#ffffff",
                fontSize: "14",
              },
              c: {
                color: "#ffffff",
                fontFamily: "UniDreamLED",
                fontSize: "20",
                width: 25,
                align: "center",
              },
              d: {
                borderColor: "rgba(31,222,233,0.5000)",
                borderWidth: 1,
              },
            },
          },
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          formatter: function (name) {
            let data = option.series[0].data;
            let tarValue = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value;
              }
            }
            let arr = [
              "{a|" + name + "}" + "{c|" + tarValue + "}" + "{b|" + "人" + "}",
            ];

            return arr.join("\n");
          },
          borderColor: "rgba(31,222,233,0.5000)",
          borderWidth: 0,
          shadowColor: "red", //阴影颜色
          shadowOffsetX: [10, 10], //阴影水平方向上的偏移距离
          shadowOffsetY: 1, //阴影垂直方向上的偏移距离
          shadowBlur: 8,
          padding: [10, 15, 8, 10],
        },
        color: ["#C174F3", "#29C2A1FF", "#287DE3FF"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["100%", "0%"],
            // center: ["25%", "50%"],
            silent: true, //禁用地图的hover事件
            avoidLabelOverlap: false,
            legendHoverLink: false,
            labelLayout: {
              hideOverlap: false,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.arr,
            label: {
              normal: {
                position: "inner",
                show: true,
                formatter: "{c}",
                color: "#fff",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.renyuantu {
  width: 100%;
  height: 100%;
  display: flex;
}

#renyuan {
  width: 50%;
  height: 100%;
}
.tuli{
  width:50%;
  height: 100%;
   display: flex;
   align-items: center;
}
ul {
  list-style: none;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.kuang {
  width: 87%;
  height: 26px;
  // height: 16%;
  box-shadow: inset 0px 0px 4px 2px rgba(56, 147, 192, 0.5);
  border: 1px solid rgba(31, 222, 233, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 10px;
}
.fontOne {
  font-size: 20px;
  font-family: UniDreamLED;
  color: #ffffff;
}
.fontTwo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.fontThree {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.jianju {
  margin: 0 0 0 8px;
}
.juzhong{
  width: 30px;
  text-align: center;
}
.flexs{
  display: flex;
  line-height: 36px;
}
</style>