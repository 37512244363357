<!-- 无人机执行任务总量图表 -->
<template>
  <div id="uav"></div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
    data() {
        return {
            names:[],
            today:[],
            yue:[],
            zongshu:[]
        }
    },
  async mounted() {
    await this.abc();
    this.getEcharts();
  },
  methods: {
    async abc() {
      await API.renwu().then((res) => {
        res.forEach(item => {
            // 各部门名
            this.names.push(item.orgName)
            // 今日数据
            this.today.push(item.todayCount)
            // 本月数据
            this.yue.push(item.monthCount)
            // 总数
            this.zongshu.push(item.allCount)
        })
      });
     
    },
    getEcharts() {
        // let a = JSON.parse(JSON.stringify(this.names))
        // let b = JSON.parse(JSON.stringify(this.today))
        // let c = JSON.parse(JSON.stringify(this.yue))
        // let d = JSON.parse(JSON.stringify(this.zongshu))

      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        0,
        1,
        0,
        [
          { offset: 0, color: "#FFBD00FF" },
          { offset: 1, color: "#FFFDC4FF" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        0,
        1,
        0,
        [
          { offset: 0, color: "#00BECDFF" },
          { offset: 1, color: "#C0FFEDFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        0,
        1,
        0,
        [
          { offset: 0, color: "#0500C5FF" },
          { offset: 1, color: "#97E6FFFF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uav"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        color: ["#F8CE55FF", "#0CDED3FF", "#44AFFFFF"],
        legend: {
          data: ["    今日", "    本月", "    总数"],
          //   color:['#F8CE55FF','#0CDED3FF','#44AFFFFF']
          textStyle: {
            fontSize: 14, //字体大小
            color: "auto", //字体颜色
          },
          left: "0",
          top: "1",
          itemGap: 40,
          itemWidth: 24, //图例标记的图形宽度
          itemHeight: 10, //图例标记的图形高度
               
        },
        grid: {
          top: "40",
          left: "1%",
          right: "1%",
          bottom: "-5",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        yAxis: {
          type: "category",
          data: this.names,
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "14",
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
            textStyle: {
              color: "none",
            },
          },
        },
        series: [
          {
            name: "    今日",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            barWidth: 12,
            emphasis: {
              focus: "series",
            },
            textStyle: {
            //   color: "#F8CE55FF",
            },
            color: color1,
            data: this.today,
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "",
              },
            },
          },
          {
            name: "    本月",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            color: color2,
            data:this.yue,
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "",
              },
            },
          },
          {
            name: "    总数",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            color: color3,
            emphasis: {
              focus: "series",
            },
            data:this.zongshu,
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "",
              },
            },
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "",
              },
            },
          },
          //   {
          //     name: "Video Ad",
          //     type: "bar",
          //     stack: "total",
          //     label: {
          //       show: true,
          //     },
          //     emphasis: {
          //       focus: "series",
          //     },
          //     data: [150, 212, 201, 154, 190, 330, 410],
          //     label: {
          //       normal: {
          //         position: "inner",
          //         show: false,
          //         formatter: "",
          //       },
          //     },
          //   },
          //   {
          //     name: "Search Engine",
          //     type: "bar",
          //     stack: "total",
          //     label: {
          //       show: true,
          //     },
          //     emphasis: {
          //       focus: "series",
          //     },
          //     data: [820, 832, 901, 934, 1290, 1330, 1320],
          //     label: {
          //       normal: {
          //         position: "inner",
          //         show: false,
          //         formatter: "",
          //       },
          //     },
          //   },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#uav {
  width: 100%;
  height: 100%;
}
</style>